import React, { useState } from "react";
import "../assets/themes.css";
import Erp2 from "../assets/erp.png";
import MDM from "../assets/mdm.png";
import warehouse from "../assets/warehouse.png";
import pbi from "../assets/pbi.png";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "./Header";
import Dcat from "../assets/d-catalogue.png";
import FuelM from "../assets/f.png";
import Digital from "../assets/digital_link.png";
import Footer from "./Footer";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import UserUtils from "../../basecomp/auth/UserUtils";

const HomePage = () => {
  const { getUserName } = UserUtils;
  const [isHovered, setIsHovered] = useState(false);

  const userName = getUserName();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Define the common content
  const Warehouse = (
    <div className="image-container">
      <img
        src={isHovered ? warehouse : warehouse}
        alt={isHovered ? "Hover" : "Normal"}
        className="image"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );

  const DCatalogue = (
    <div className="image-container">
      <img
        src={isHovered ? Dcat : Dcat}
        alt={isHovered ? "Hover" : "Normal"}
        className="image"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );

  return (
    <>
      <div style={{ backgroundColor: "#F6F4F9", paddingTop: "5em" }}>
        <Header />
        <div id="home">
          <div
            className="container-xxl bg-primary hero-header"
            style={{ backgroundColor: "#13226c" }}
          >
            <div className="px-lg-5">
              <div className="align-items-end">
                <h1
                  className="text-white animated slideInDown fontclass"
                  align="center"
                  style={{ fontWeight: "700" }}
                >
                  WHY CONAS?
                  <br />
                </h1>
                <hr
                  className="thin-line"
                  style={{
                    width: "100px",
                    height: "1px",
                    backgroundColor: "#808080",
                    border: "none",
                  }}
                ></hr>
                <div
                  className="col-lg-12 fontclass"
                  style={{ color: "white", margin: "2em" }}
                >
                  <p style={{ fontSize: "1.5rem", color: "white" }}>
                    Conas is a Consultancy Company specialized in Service
                    Logistics. We help our Customers to manage their spare parts
                    supply chain:
                  </p>
                  <ul
                    type="square"
                    className="text-white fontclass"
                    align="justify"
                    style={{ fontSize: "1.5rem" }}
                  >
                    <li>We clean, normalize and enrich spare part data.</li>
                    <li>
                      We globalize spart part data management by linking the
                      data of individual plants and assigning an internal global
                      company spare part ID number.
                    </li>
                    <li>
                      We digitalize equipment spare part manuals of the
                      equipment manufacturers and link them to the internal
                      company spare part references.
                    </li>
                    <li>
                      We offer software solutions, complementary to the
                      customers ERP system, to help them to better manage spare
                      parts and to make spare part information easily available
                      to the users by digital link / resolver techniques.
                    </li>
                    <li>
                      We support customers with spare part warehouse design and
                      layout.
                    </li>
                    <li>
                      We clean, reorganize spare part warehouses and implement
                      the basics of 5S
                      <i>
                        “a place for every part & every part in its place.”{" "}
                      </i>
                    </li>
                    <li>We are experts in applying the GS1 data standards.</li>
                    <li>
                      We are specialized in the Marine, Ports & Terminals
                      segments.
                    </li>
                  </ul>
                  <b>
                    <p style={{ fontSize: "1.5rem" }}>
                      We operate from our offices in Voorschoten, The
                      Netherlands and Pune, India. We support our customers
                      globally and are active in 20+ countries across 6
                      continents.
                    </p>
                  </b>
                </div>

                <div className="col-lg-12 text-center text-lg-start">
                  <hr
                    className="thin-line"
                    style={{
                      width: "100px",
                      height: "1px",
                      backgroundColor: "#808080",
                      border: "none",
                    }}
                  ></hr>
                  <br />
                  <b>
                    <p
                      className="text-white fontclass animated slideInDown"
                      style={{ fontSize: "1.63rem" }}
                    >
                      It is not just the data services we provide, we provide
                      end to end solutions for our customers, which includes:
                    </p>
                  </b>
                </div>
                <br></br>

                <Grid
                  container
                  spacing={8}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item xs={12} sm={6} md={3.8}>
                    <Card
                      className="slide-up-animation"
                      variant="outlined"
                      style={{ border: "none", borderRadius: "10px" }}
                    >
                      <div className="fontclass">
                        <center>
                          <LayersOutlinedIcon
                            style={{ fontSize: "50px", paddingTop: "0.5em" }}
                          />
                          <h4>
                            Gathering of business requirements & <br></br>{" "}
                            Provide operational assistance.
                          </h4>
                        </center>
                      </div>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3.8}>
                    <Card
                      className="slide-up-animation"
                      variant="outlined"
                      style={{ border: "none", borderRadius: "10px" }}
                    >
                      <div className="fontclass">
                        <center>
                          <LoopOutlinedIcon
                            style={{ fontSize: "50px", paddingTop: "0.5em" }}
                          />
                          <h4>
                            Setting new processes as per business <br></br>
                            requirements.
                          </h4>
                        </center>
                      </div>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3.8}>
                    <Card
                      className="slide-up-animation"
                      variant="outlined"
                      style={{ border: "none", borderRadius: "10px" }}
                    >
                      <div className="fontclass">
                        <center>
                          <TrendingUpOutlinedIcon
                            style={{ fontSize: "50px", paddingTop: "0.5em" }}
                          />
                          <h4>
                            Providing customized data strategy and <br></br>{" "}
                            setting up reporting and analytics.
                          </h4>
                        </center>
                      </div>
                    </Card>
                  </Grid>
                </Grid>

                <br></br>
                <br></br>

                <Grid
                  container
                  spacing={8}
                  style={{ justifyContent: "center" }}
                >
                  <Grid item xs={12} sm={6} md={3.8}>
                    <Card
                      className="slide-up-animation"
                      variant="outlined"
                      style={{ border: "none", borderRadius: "10px" }}
                    >
                      <div className="fontclass">
                        <center>
                          <DesktopWindowsOutlinedIcon
                            style={{ fontSize: "50px", paddingTop: "0.5em" }}
                          />
                          <h4 style={{ marginTop: "0.1em" }}>
                            Change management<br></br>(training resources on new
                            process,<br></br> applications and platforms.)
                          </h4>
                        </center>
                      </div>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3.8}>
                    <Card
                      className="slide-up-animation"
                      variant="outlined"
                      style={{ border: "none", borderRadius: "10px" }}
                    >
                      <div className="fontclass">
                        <center>
                          <MonetizationOnOutlinedIcon
                            style={{ fontSize: "50px", paddingTop: "0.5em" }}
                          />
                          <h4>
                            Creating dollar values from the reports and{" "}
                            <br></br> cleaned data.
                          </h4>
                        </center>
                      </div>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3.8}>
                    <Card
                      className="slide-up-animation"
                      variant="outlined"
                      style={{ border: "none", borderRadius: "10px" }}
                    >
                      <div className="fontclass">
                        <center>
                          <CheckBoxOutlinedIcon
                            style={{ fontSize: "50px", paddingTop: "0.5em" }}
                          />
                          <h4>
                            Providing governance and compliance
                            <br></br>
                            to the processors.
                          </h4>
                        </center>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>

        <div id="services">
          <div className="header fontclass slide-up-animation">
            <h1>What Services We Provide</h1>
          </div>

          <div className="card-row">
            <Grid container spacing={3} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={6} md={2.8}>
                <Card
                  className="slide-up-animation"
                  variant="outlined"
                  style={{ border: "none", borderRadius: "10px 10px 0 0" }}
                >
                  <Link to="/ERP-Implementation">
                    <div className="image-container">
                      <img
                        src={isHovered ? Erp2 : Erp2}
                        alt={isHovered ? "Hover" : "Normal"}
                        className="image"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                    </div>
                  </Link>
                  <div className="bg-light p-4 fontclass">
                    <center>
                      <h3 style={{ whiteSpace: "nowrap" }}>
                        ERP Implementation
                      </h3>
                    </center>
                  </div>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={2.8}>
                <Card
                  className="slide-up-animation"
                  variant="outlined"
                  style={{ border: "none", borderRadius: "10px 10px 0 0" }}
                >
                  <Link to="/MDM">
                    <div className="image-container">
                      <img
                        src={isHovered ? MDM : MDM}
                        alt={isHovered ? "Hover" : "Normal"}
                        className="image"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                    </div>
                  </Link>
                  <div className="bg-light p-4 fontclass">
                    <center>
                      <h3 style={{ whiteSpace: "nowrap" }}>
                        Master Data Management
                      </h3>
                    </center>
                  </div>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={2.8}>
                <Card
                  className="slide-up-animation"
                  variant="outlined"
                  style={{ border: "none", borderRadius: "10px 10px 0 0" }}
                >
                  <Link to="/Warehouse">
                    <div className="image-container">
                      <img
                        src={isHovered ? warehouse : warehouse}
                        alt={isHovered ? "Hover" : "Normal"}
                        className="image"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                    </div>
                  </Link>
                  <div className="bg-light p-4 fontclass">
                    <center>
                      <h3 style={{ whiteSpace: "nowrap" }}>
                        Warehouse Management
                      </h3>
                    </center>
                  </div>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={2.8}>
                <Card
                  className="slide-up-animation"
                  variant="outlined"
                  style={{ border: "none", borderRadius: "10px 10px 0 0" }}
                >
                  <Link to="/PowerBI">
                    <div className="image-container">
                      <img
                        src={isHovered ? pbi : pbi}
                        alt={isHovered ? "Hover" : "Normal"}
                        className="image"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                    </div>
                  </Link>
                  <div className="bg-light p-4 fontclass">
                    <center>
                      <h3 style={{ whiteSpace: "nowrap" }}>Power BI Reports</h3>
                    </center>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>

        <div id="solutions">
          <div className="header fontclass slide-up-animation">
            <h1>Our Solutions</h1>
          </div>

          <div className="card-row">
            <Grid container spacing={3} style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={6} md={2.8}>
                <Card
                  className="slide-up-animation delay-1"
                  variant="outlined"
                  style={{ border: "none", borderRadius: "10px 10px 0 0" }}
                >
                  {userName ? (
                    <Link to="/d-catalogue/historical-job">{DCatalogue}</Link>
                  ) : (
                    <Link to="/DCatalogue">{DCatalogue}</Link>
                  )}
                  <div className="bg-light p-4 fontclass">
                    <center>
                      <h3 style={{ whiteSpace: "nowrap" }}>D-Catalogue</h3>
                    </center>
                  </div>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={2.8}>
                <Card
                  className="slide-up-animation delay-3"
                  variant="outlined"
                  style={{ border: "none", borderRadius: "10px 10px 0 0" }}
                >
                  {userName ? (
                    <Link to="/warehouse/label-printing">{Warehouse}</Link>
                  ) : (
                    <Link to="/New">{Warehouse}</Link>
                  )}
                  <div className="bg-light p-4 fontclass">
                    <center>
                      <h3 style={{ whiteSpace: "nowrap" }}>
                        Warehouse Management
                      </h3>
                    </center>
                  </div>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={2.8}>
                <Card
                  className="slide-up-animation delay-3"
                  variant="outlined"
                  style={{ border: "none", borderRadius: "10px 10px 0 0" }}
                >
                  <Link to="/DigitalLink">
                    <div className="image-container">
                      <img
                        src={isHovered ? Digital : Digital}
                        alt={isHovered ? "Hover" : "Normal"}
                        className="image"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                    </div>
                  </Link>
                  <div className="bg-light p-4 fontclass">
                    <center>
                      <h3 style={{ whiteSpace: "nowrap" }}>Digital Link</h3>
                    </center>
                  </div>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={2.8}>
                <Card
                  className="slide-up-animation delay-2"
                  variant="outlined"
                  style={{ border: "none", borderRadius: "10px 10px 0 0" }}
                >
                  <Link to="/FuelManagement">
                    <div className="image-container">
                      <img
                        src={isHovered ? FuelM : FuelM}
                        alt={isHovered ? "Hover" : "Normal"}
                        className="image"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                    </div>
                  </Link>
                  <div className="bg-light p-4 fontclass">
                    <center>
                      <h3 style={{ whiteSpace: "nowrap" }}>Fuel Management</h3>
                    </center>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
