import React from "react";
import {
  Grid,
  Typography,
  AppBar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { themeMain } from "../components/wrapper/CThemeProvider";
import mgt from "../../main/assets/image.png";

const ResolverHeader = ({ cLogo, pageTitle }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const terminal = localStorage.getItem("terminal");

  return (
    <AppBar
      sx={{
        backgroundColor: themeMain.palette.mainbg.main,
        padding: "0.5em 1em",
      }}
    >
      <Grid container alignItems="center" sx={{ width: "100%" }}>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {terminal == 4300 ? (
            <img
              src={mgt}
              alt="mgt"
              style={{ height: isXs ? "25px" : "42px" }}
            />
          ) : (
            cLogo && (
              <img
                src={cLogo}
                alt="cLogo"
                style={{ height: isXs ? "25px" : "38px" }}
              />
            )
          )}
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            color="secondary"
            fontSize={isXs ? "1.5rem" : "2rem"}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {pageTitle}
          </Typography>
        </Grid>

        {terminal == 4300 && (
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {cLogo && (
              <img
                src={cLogo}
                alt="cLogo"
                style={{ height: isXs ? "25px" : "38px" }}
              />
            )}
          </Grid>
        )}
      </Grid>
    </AppBar>
  );
};

export default ResolverHeader;
