import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SaveIcon from "@mui/icons-material/Save";
import PreviewIcon from "@mui/icons-material/Preview";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination,
  TableContainer,
  IconButton,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MaterialSearch from "./MaterialSearch";
import JobDetails from "./JobDetails";
import Replenishment from "./Replenishment";
import SBHeader from "./SBHeader";
import ShoppingBasketVM from "../viewmodel/ShoppingBasketVM";
import CCarousel from "../../../../basecomp/components/CCarousel";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import CText from "../../../../basecomp/components/CText";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
function ShoppingBasket() {
  const {
    handleImageClick,
    DeleteAll,
    handleEdit,
    handleSave,
    handleDelete,
    handleChangePage,
    handleChangeRowsPerPage,
    handleGreyRecords,
    setExternalRef,
    setJobDirective,
    setEquipmentType,
    setEquipmentID,
    dropdownData,
    page,
    rowsPerPage,
    editingId,
    startDate,
    finishDate,
    isMaterialSearchUI,
    setIsMaterialSearchUI,
    isShoppingBasketUI,
    setIsShoppingBasketUI,
    isJobDetailsUI,
    setIsobDetailsUI,
    isReplenishmentUI,
    setIsReplenishmentUI,
    section,
    setSection,
    jobType,
    setJobType,
    resources,
    setResources,
    workDesc,
    setWorkDesc,
    externalRef,
    jobDirective,
    equipmentType,
    equipmentID,
    spDetails,
    setClickedImageIndex,
    editedQuantity,
    setEditedQuantity,
    setSpDetails,
    setLightboxOpen,
    setSliderImages,
    jobCardNumber,
    spData,
    setSpData,
    setStartDate,
    setFinishDate,
    handleToastOpen,
    lclMaterialNo,
    clickedImageIndex,
    index,
    setOpenCarousel,
    sliderImages,
    openCarousel,
    setlclMaterialNo,
    handleAddImage,
    isLoading,
    handleIconButtonClick,
  } = ShoppingBasketVM();
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={10} sm={8} md={10} sx={{ margin: "5em 3em 0.5em 3em" }}>
        <Card sx={{ padding: "2em", marginBottom: "2em" }}>
          <Grid item xs={12} sm={12} md={12}>
            <TableContainer>
              <SBHeader
                spData={spData}
                setSpDetails={setSpDetails}
                spDetails={spDetails}
                setSpData={setSpData}
                setIsMaterialSearchUI={setIsMaterialSearchUI}
                setIsShoppingBasketUI={setIsShoppingBasketUI}
                setIsobDetailsUI={setIsobDetailsUI}
                setIsReplenishmentUI={setIsReplenishmentUI}
                section={section}
                jobType={jobType}
                resources={resources}
                startDate={startDate}
                finishDate={finishDate}
                workDesc={workDesc}
                dropdownData={dropdownData}
                externalRef={externalRef}
                setExternalRef={setExternalRef}
                jobDirective={jobDirective}
                setJobDirective={setJobDirective}
                equipmentType={equipmentType}
                setEquipmentType={setEquipmentType}
                equipmentID={equipmentID}
                setEquipmentID={setEquipmentID}
                handleToastOpen={handleToastOpen}
              />

              {isMaterialSearchUI && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ margin: "1.5em .9em" }}
                  >
                    <CText cVariant="header_card" cText="Shopping Basket" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={11.9}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: ".8em",
                    }}
                  >
                    <Tooltip title="Clear Basket">
                      <IconButton
                        id="sp_delete_all"
                        style={{
                          backgroundColor: "#052660",
                          color: "#FFFFFF",
                          borderRadius: 5,
                          padding: "10px",
                          marginBottom: ".5em",
                        }}
                        onClick={DeleteAll}
                      >
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} margin={1.5}>
                    <div style={{ overflowX: "auto" }}>
                      <Table id="sp_table">
                        <TableHead
                          sx={{
                            backgroundColor: "#052660",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            overflowX: "auto",
                            "& .MuiTableRow-root": {
                              height: "48px", // Custom header row height
                            },
                            "& .MuiTableCell-root": {
                              padding: "0 16px", // Minimal padding for header cells
                            },
                          }}
                        >
                          <TableRow>
                            <TableCell>
                              <CameraAltIcon
                                style={{
                                  marginLeft: ".5em",
                                  color: "white",
                                  fontSize: "21px",
                                }}
                              />
                            </TableCell>
                            {spData &&
                            spData.length > 0 &&
                            spData[0].terminal_id === 1010 ? (
                              <TableCell align="center" sx={{ color: "white" }}>
                                PDF
                              </TableCell>
                            ) : null}
                            {spData &&
                            spData.length > 0 &&
                            spData[0].terminal_id === 1010 ? (
                              <TableCell align="center" sx={{ color: "white" }}>
                                HSN code
                              </TableCell>
                            ) : null}
                            <TableCell align="center" sx={{ color: "white" }}>
                              OEM No.
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              OEM Description
                            </TableCell>
                            {spData &&
                            spData.length > 0 &&
                            spData[0].terminal_id !== 1010 ? (
                              <TableCell align="center" sx={{ color: "white" }}>
                                {" "}
                                Notes
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {spData &&
                            spData.length > 0 &&
                            spData[0].terminal_id !== 1010 ? (
                              <TableCell align="left" sx={{ color: "white" }}>
                                Global Material No.
                              </TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell align="left" sx={{ color: "white" }}>
                              {" "}
                              Material
                            </TableCell>
                            <TableCell align="left" sx={{ color: "white" }}>
                              {" "}
                              Description
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              {" "}
                              QTY
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              {" "}
                              UOM
                            </TableCell>
                            <TableCell align="center">
                              <BorderColorIcon
                                sx={{ color: "white", fontSize: "17px" }}
                              />
                            </TableCell>{" "}
                            <TableCell align="center">
                              <DeleteOutlinedIcon
                                fontSize="small"
                                sx={{ color: "white", fontSize: "21px" }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            "& .MuiTableRow-root": {
                              height: "40px",
                            },
                            "& .MuiTableCell-root": {
                              padding: "0 16px",
                            },
                          }}
                        >
                          {spData && spData.length > 0 ? (
                            spData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((data) => (
                                <TableRow
                                  key={data.id}
                                  style={{
                                    backgroundColor: data.material_no
                                      ? "#63b144"
                                      : "#E0E0E0",
                                  }}
                                >
                                  <TableCell>
                                    {data.image_link.length === 0 ||
                                    data.image_link === undefined ? (
                                      <Tooltip title="Upload Image">
                                        <IconButton
                                          id="add_image"
                                          onClick={() =>
                                            handleAddImage(data.material_no)
                                          }
                                        >
                                          <AddAPhotoIcon
                                            sx={{
                                              fontSize: "22px",
                                              color: "#052660",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="View Image">
                                        <IconButton
                                          id="display_image"
                                          onClick={() =>
                                            handleImageClick(
                                              data.image_link,
                                              index
                                            )
                                          }
                                        >
                                          <PreviewIcon
                                            sx={{
                                              fontSize: "24px",
                                              color: "#052660",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  {data.terminal_id === 1010 ? (
                                    <TableCell align="center">
                                      {data.pdfAndHsnCode !== null &&
                                      data.pdfAndHsnCode.pdf !== null ? (
                                        <Tooltip title="View PDF">
                                          <IconButton id="view_pdf">
                                            <PictureAsPdfIcon
                                              sx={{ color: "#052660" }}
                                              onClick={() =>
                                                window.open(
                                                  data.pdfAndHsnCode.pdf,
                                                  "_blank"
                                                )
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {data.terminal_id === 1010 ? (
                                    <TableCell align="center">
                                      {data.pdfAndHsnCode !== null
                                        ? data.pdfAndHsnCode.hsn_code
                                        : ""}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}

                                  <TableCell align="center">
                                    {data.oem_no}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data.oem_description}
                                  </TableCell>
                                  {data.terminal_id !== 1010 ? (
                                    <TableCell align="center">
                                      {data.notes}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  {data.terminal_id !== 1010 ? (
                                    <TableCell align="left">
                                      {data.global_material_no
                                        ? data.global_material_no
                                        : data.gbl_material_number || ""}
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  <TableCell align="left">
                                    {data.material_no}
                                  </TableCell>
                                  <TableCell align="left">
                                    {data.global_material_desc
                                      ? data.global_material_desc
                                      : data.gbl_material_desc || ""}
                                  </TableCell>
                                  <TableCell align="center">
                                    {editingId === data.id ? (
                                      <input
                                        id="edit_quantity"
                                        type="text"
                                        value={editedQuantity}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;
                                          // Check if the input is a valid positive number
                                          if (
                                            /^\d+$/.test(inputValue) ||
                                            inputValue === ""
                                          ) {
                                            setEditedQuantity(inputValue);
                                          }
                                        }}
                                      />
                                    ) : (
                                      data.quantity
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    {data.uom}
                                  </TableCell>
                                  <TableCell align="center">
                                    {editingId === data.id ? (
                                      <Tooltip title="Save">
                                        <IconButton
                                          id="save_quantity"
                                          onClick={() =>
                                            handleSave(data.id, editedQuantity)
                                          }
                                        >
                                          <SaveIcon
                                            fontSize="small"
                                            sx={{ color: "#052660" }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Edit">
                                        <IconButton
                                          id="edit_quantity"
                                          onClick={() =>
                                            handleEdit(data.id, data.quantity)
                                          }
                                        >
                                          <BorderColorIcon
                                            sx={{
                                              fontSize: "18px",
                                              color: "#052660",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>

                                  <TableCell align="center">
                                    <Tooltip title="Delete">
                                      <IconButton
                                        id="delete_row"
                                        onClick={() => handleDelete(data.id)}
                                      >
                                        <DeleteOutlinedIcon
                                          sx={{
                                            color: "#052660",
                                            fontSize: "22px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))
                          ) : (
                            <TableRow style={{ margin: "1em" }}></TableRow>
                          )}

                          {openCarousel && (
                            <CCarousel
                              cOpen={openCarousel}
                              images={sliderImages}
                              currentIndex={clickedImageIndex || 0} // Provide a default value if clickedImageIndex is undefined
                              cClose={() => setOpenCarousel(false)}
                            />
                          )}
                        </TableBody>
                      </Table>
                    </div>
                    {spData && spData.length > 0 && (
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={spData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </Grid>

                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: ".7em",
                      }}
                    >
                      <Tooltip title="Send mail for Material Number Request">
                        <div>
                          {spData && spData.length === 0 ? (
                            <IconButton
                              id="grey_record"
                              style={{
                                backgroundColor: "#E0E0E0",
                                color: "#FFFFFF",
                                borderRadius: 4,
                                height: "1.1em",
                                width: "1.4em",
                              }}
                              disabled={true}
                            >
                              <LibraryAddOutlinedIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton
                              id="grey_record"
                              style={{
                                backgroundColor:
                                  spData &&
                                  spData.some(
                                    (item) =>
                                      item.material_no === null ||
                                      item.material_no === ""
                                  )
                                    ? "#052660"
                                    : "#E0E0E0",
                                color: "#FFFFFF",
                                borderRadius: 5,
                                padding: "10px",
                                pointerEvents:
                                  spData &&
                                  spData.some(
                                    (item) =>
                                      item.material_no === null ||
                                      item.material_no === ""
                                  )
                                    ? "auto"
                                    : "none",
                              }}
                              disabled={
                                spData &&
                                !spData.some(
                                  (item) =>
                                    item.material_no === null ||
                                    item.material_no === ""
                                )
                              }
                              onClick={handleGreyRecords}
                            >
                              <LibraryAddOutlinedIcon fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                      </Tooltip>

                      <div style={{ display: "flex" }}>
                        <Tooltip title="Email">
                          <IconButton
                            id="all_record"
                            style={{
                              backgroundColor:
                                spData && spData.length === 0
                                  ? "#E0E0E0"
                                  : "#052660",
                              color: "#FFFFFF",
                              borderRadius: 5,
                              padding: "10px",
                              pointerEvents:
                                spData && spData.length === 0 ? "none" : "auto",
                            }}
                            onClick={handleIconButtonClick}
                          >
                            <MailOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
              {isShoppingBasketUI && (
                <MaterialSearch
                  setSpDetails={setSpDetails}
                  setSpData={setSpData}
                  setLightboxOpen={setLightboxOpen}
                  setClickedImageIndex={setClickedImageIndex}
                  setSliderImages={setSliderImages}
                  jobCardNumber={jobCardNumber}
                  handleToastOpen={handleToastOpen}
                  lclMaterialNo={lclMaterialNo}
                  setlclMaterialNo={setlclMaterialNo}
                />
              )}
              {isJobDetailsUI && (
                <div>
                  <JobDetails
                    spDetails={spDetails}
                    spData={spData}
                    externalRef={externalRef}
                    jobDirective={jobDirective}
                    equipmentType={equipmentType}
                    equipmentID={equipmentID}
                    section={section}
                    setSection={setSection}
                    jobType={jobType}
                    setJobType={setJobType}
                    resources={resources}
                    setResources={setResources}
                    workDesc={workDesc}
                    setWorkDesc={setWorkDesc}
                    jobCardNumber={jobCardNumber}
                    startDate={startDate}
                    finishDate={finishDate}
                    setStartDate={setStartDate}
                    setFinishDate={setFinishDate}
                    handleToastOpen={handleToastOpen}
                  />
                </div>
              )}
              {isReplenishmentUI && (
                <Replenishment
                  spDetails={spDetails}
                  spData={spData}
                  jobDirective={jobDirective}
                  equipmentType={equipmentType}
                  equipmentID={equipmentID}
                  jobCardNumber={jobCardNumber}
                  handleToastOpen={handleToastOpen}
                  jobType={jobType}
                  setJobType={setJobType}
                />
              )}
            </TableContainer>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default ShoppingBasket;
