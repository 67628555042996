import React from "react";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import CDropDown from "../../../basecomp/components/CDropDown";
import CButton from "../../../basecomp/components/CButton2";
import CChooseFile from "../../../basecomp/components/CChooseFile";
import UploadLabelVM from "../viewmodel/UploadLabelVM";
import CTable2 from "../../../basecomp/components/CTable2";
import WarehouseLocationVM from "../viewmodel/WarehouseLocationVm";

const UploadLabelView = () => {
  const {
    sizeOptions,
    handleSizeChange,
    handleSampleFileDownload,
    handleFileChoose,
    handleLabelFileUpload,
  } = UploadLabelVM();
  const { wareHouseData, infoHeadData, infoRowData , handleSampleWarehouseFileDownload} = WarehouseLocationVM();
  return (
    <Grid
      item
      xs={10}
      sm={10}
      md={10}
      lg={10}
      xl={10}
      sx={{ margin: "5em 0.5em 0.5em 0.5em" }}
    >
      <Card sx={{ padding: "2.5em" }}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="right"
            marginBottom={2}
            gap={1}
          >
            <CButton
              cText="Labels File"
              cTooltipText="Download the sample labels file"
              cOnClick={handleSampleFileDownload}
            />
            <CButton
              cText="Warehouse Locations File"
              cTooltipText="Download the sample warehouse locations file"
              cOnClick={handleSampleWarehouseFileDownload}
            />
          </Grid>
          <CDropDown
            cHelperText="Size"
            cData={sizeOptions}
            cIsOptionName={"size"}
            cOnChange={handleSizeChange}
            cFullWidth={true}
            cIsRequired={true}
          />
          <CChooseFile
            cFileType="XCL"
            cTooltipText="Upload excel"
            cHelperText="*File Types Excel/CSV"
            cHandleFileChange={handleFileChoose}
            cFullWidth={true}
            cIsRequired={true}
          />
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            justifyContent="center"
            style={{ paddingBottom: "2.5em" }}
          >
            <CButton
              cText={"Upload"}
              cTooltipText="Upload the label file"
              cOnClick={handleLabelFileUpload}
            />
          </Grid>
        </Grid>
        {wareHouseData.length > 0 && (
          <CTable2
            cHeaderData={infoHeadData}
            cRowData={infoRowData}
            cActualData={wareHouseData}
            cIsPagination={true}
            cSmallSize={true}
          />
        )}
      </Card>
    </Grid>
  );
};

export default UploadLabelView;
