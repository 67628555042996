import AxiosWrapper from "../../../basecomp/base/AxiosWrapper";
import Logger from "../../../basecomp/base/Logger";
const Base_URL = process.env.REACT_APP_BE_URL;
const { axiosGetCallWithToken, axiosPostCallWithToken } = AxiosWrapper;

export const checkAssetType = async (id) => {
  const response = await axiosGetCallWithToken(
    `${Base_URL}/gsone/getAssetDetails?id=${id}`
  );
  return response;
};

//registration page to fetch plant list
export const getPlantOptions = async () => {
  const response = await axiosGetCallWithToken(`${Base_URL}/users/getAllTerminals`);
  return response;
};

//registration page to add records for specific asset id

export const submitFormData = async (formData) => {
  const response = await axiosPostCallWithToken(
    `${Base_URL}/gsone/addAssetData`,
    formData
  );
  return response;
};

//sparepart info page to fetch spare details for specific material

export const getSparePartDetails = async (material) => {
  sessionStorage.setItem(
    "logingsoneurl",
    `${Base_URL}/warehouse/spare-part-info?material_no=${material}`
  );
  const response = await axiosGetCallWithToken(
    `${Base_URL}/gsone/getSparePartDetails?material=${material}`
  );
  return response;
};

// Function to fetch row data based on ID from sq01
export const getSQ01ByID = async (id) => {
  const response = await axiosGetCallWithToken(
    `${Base_URL}/common/getSq01CatById?sq_id=${id}`
  );
  return response;
};

//fetch spbasketID to insert records in spbasket table
export const getSpBasketID = async () => {
  const response = await axiosGetCallWithToken(`${Base_URL}/dcatalogue/getSpBasketID`);
  return response;
};

// add data to spbasket table
export const postSpBasket = async (data) => {
  const response = await axiosPostCallWithToken(
    `${Base_URL}/dcatalogue/addSpBasket`,
    data
  );
  return response;
};

// fetch fuel asset records
export const fetchMaterialData = async (asset_id, terminal) => {
  const response = await axiosGetCallWithToken(
    `${Base_URL}/get_gsone_data?equipment_number=${asset_id}&terminal=${terminal}`
  );
  return response;
};

// Function to fetch notification
export const getNotificationDetails = async (asset) => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/gsone/getNotificationDetails?asset=${asset}`
    );
    return response.data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching sq01catById:", error);
  }
};

export const getWorkOrderDetails = async (asset,equipment) => {
  sessionStorage.setItem(
    "logingsoneurl",
    `/warehouse/asset-info?asset_id=${asset}`
  );
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/gsone/getWorkOrderDetails?asset=${asset}&equipment=${equipment}`
    );
    return response.data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching sq01catById:", error);
  }
};

export const getDCatTerminalDetails = async (asset) => {
  try {
    const response = await axiosGetCallWithToken(
      `${Base_URL}/gsone/getDCatTerminalDetails?asset=${asset}`
    );
    return response.data;
  } catch (error) {
    Logger.messageStackTrace("Error fetching sq01catById:", error);
  }
};