import axios from "axios";
import tracker from "../../tracker/UserActivityTracker";
const NO_TOKEN_MSG = "No Token Present";
const REACT_BE_URL = process.env.REACT_APP_BE_URL2;

const handleLoggedIn = async (result) => {
  const fetchIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Failed to fetch IP address:", error);
      return "Unknown IP";
    }
  };

  const userIp = await fetchIpAddress();
  localStorage.setItem("email", result.email);
  localStorage.setItem("token", result.token);
  localStorage.setItem("userName", result.userName);
  localStorage.setItem("customerName", result.customerName);
  localStorage.setItem("permissionData", JSON.stringify(result.permissionData)); 
  localStorage.setItem("terminal", result.terminal_id);
  localStorage.setItem("terminal_name", result.terminal_name);
  const loginDetails = {
    userName: result.userName,
    email: result.email,
    terminal: result.terminal_id,
    ip: userIp,
  };
  try {
    const response = await axios.post(
      `${REACT_BE_URL}/tracker/addLoginDetailsTracker`,
      loginDetails,
      {
        headers: {
          "Content-Type": "application/json"
        },
      }
    );

    if (response.status === 201) {
      localStorage.setItem('login_session_id', response.data.id)
    } else {
      console.warn("Unexpected response:", response);
    }
  } catch (error) {
    console.warn("Unexpected response:", error.message);
  }
};


const handleLogout = async() => {
  const currentUrl = window.location.origin; 
  for (let key in sessionStorage) {
    if (!key.startsWith("login")) {
      sessionStorage.removeItem(key);
    }
  }
  const id= localStorage.getItem("login_session_id")
  await axios.put(
    `${REACT_BE_URL}/tracker/logoutUpdateApi?id=${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  await tracker.stopTracking();
  localStorage.clear();
  const redirectUrl = `${currentUrl}/login`;
  window.location.replace(redirectUrl);
};

const getUserName = () => {
  return localStorage.getItem("userName");
};

const getInitials = () => {
  let initials = "";
  const userName = getUserName();
  if (userName !== null) {
    const names = userName.split(" ");
    const filteredNames = [];
    filteredNames.push(names[0]);
    if (names.length > 1) {
      filteredNames.push(names[names.length - 1]);
    }
    initials = filteredNames.map((name) => name[0]).join("");
  }
  return initials;
};

const handleLoginError = (error) => {
  if (error.message === NO_TOKEN_MSG) {
    handleLogout();
    return;
  }
  if (error.response) {
    if (error.response.status === 401) {
      handleLogout();
      return;
    } else if (error.response.status === 403) {
      const currentUrl = window.location.origin;
      const redirectUrl = `${currentUrl}/services`;
      window.location.replace(redirectUrl);
      return;
    } else {
      throw error;
    }
  } else {
    throw error;
  }
};

const getTokenFromEmail = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken === null) {
    throw new Error(NO_TOKEN_MSG);
  }
  return storedToken;
};

const UserUtils = {
  handleLoggedIn,
  handleLogout,
  getUserName,
  getInitials,
  handleLoginError,
  getTokenFromEmail,
};

export default UserUtils;
