import React from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

const CButton2 = ({ 
  cText, 
  cSrc, 
  cTooltipText, 
  cDisabled, 
  cOnClick, 
  cLink, 
  CFullWidth, 
  cBackgroundColor,
  cTextColor 
}) => {
  const handleClick = () => {
    if (cOnClick !== undefined) {
      cOnClick();
    }
  };

  return (
    <Tooltip title={cTooltipText} arrow>
      <span>
        {cText !== undefined ? (
          <Button
            disabled={cDisabled}
            onClick={handleClick}
            color="primary"
            variant="contained"
            fullWidth={CFullWidth}
            sx={{ padding: "1em" }}
          >
            {cText}
          </Button>
        ) : cSrc !== undefined ? (
          <IconButton
            aria-label={cTooltipText}
            disabled={cDisabled}
            onClick={handleClick}
            sx={{
              padding: "8px", 
              backgroundColor: cBackgroundColor, 
              '&.Mui-disabled': {
                pointerEvents: 'auto' 
              },
              borderRadius: '5px',
              color: cTextColor,
              '&:hover': {
                backgroundColor: cBackgroundColor, 
                opacity: 0.9,
              }
            }}
          >
            {cSrc}
          </IconButton>
        ) : null}
      </span>
    </Tooltip>
  );
};

export default CButton2;