// Frontend: UserActivityTracker.js
import axios from 'axios';

const REACT_BE_URL = process.env.REACT_APP_BE_URL2;

class UserActivityTracker {
  constructor() {
    this.currentPath = window.location.pathname;
    this.startTime = null;
    this.sessionId = localStorage.getItem('login_session_id');
    this.isTracking = false;
    this.interval = null;
    this.isTabActive = !document.hidden;
    this.pausedTime = 0;
    this.lastPauseTime = null;
  }

  // Initialize tracking for a new page
  initializePage = async () => {
    if (!this.sessionId) return;

    try {
      const response = await axios.post(
        `${REACT_BE_URL}/tracker/activity`,
        {
          session_id: this.sessionId,
          path: this.currentPath,
          time_spent: 0
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      
      console.log('Page tracking initialized:', this.currentPath);
    } catch (error) {
      console.error('Failed to initialize page tracking:', error);
    }
  }

  // Calculate actual time spent considering pauses
  calculateTimeSpent = () => {
    if (!this.startTime) return 0;
    
    const currentTime = Date.now();
    const totalTime = currentTime - this.startTime - this.pausedTime;
    return Math.max(0, Math.floor(totalTime / 1000)); // Convert to seconds
  }

  // Update time spent on current page
  updateTimeSpent = async () => {
    if (!this.sessionId || !this.isTabActive) return;

    const timeSpent = this.calculateTimeSpent();

    try {
      await axios.put(
        `${REACT_BE_URL}/tracker/activity/update`,
        {
          session_id: this.sessionId,
          path: this.currentPath,
          time_spent: timeSpent
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
    } catch (error) {
      console.error('Failed to update time spent:', error);
    }
  }

  handleVisibilityChange = () => {
    if (document.hidden) {
      this.pauseTracking();
    } else {
      this.resumeTracking();
    }
  }

  handleWindowFocus = () => {
    this.resumeTracking();
  }

  handleWindowBlur = () => {
    this.pauseTracking();
  }

  pauseTracking = () => {
    this.isTabActive = false;
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.lastPauseTime = Date.now();
  }

  resumeTracking = () => {
    this.isTabActive = true;
    if (this.lastPauseTime) {
      this.pausedTime += Date.now() - this.lastPauseTime;
      this.lastPauseTime = null;
    }
    if (!this.interval && this.isTracking) {
      this.interval = setInterval(this.updateTimeSpent, 5000);
    }
  }

  // Start tracking for the current page
  startTracking = async () => {
    if (this.isTracking) return;
    
    this.isTracking = true;
    this.startTime = Date.now();
    this.pausedTime = 0;
    
    // Initialize the current page
    await this.initializePage();
    
    // Add visibility change listeners
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', this.handleWindowFocus);
    window.addEventListener('blur', this.handleWindowBlur);
    
    // Only start interval if tab is active
    if (this.isTabActive) {
      this.interval = setInterval(this.updateTimeSpent, 5000);
    }
  }

  // Handle page changes
  trackPageChange = async (newPath) => {
    if (this.currentPath === newPath) return;

    // Update final time for current page
    await this.updateTimeSpent();
    
    // Reset for new page
    this.currentPath = newPath;
    this.startTime = this.isTabActive ? Date.now() : null;
    this.pausedTime = 0;
    this.lastPauseTime = null;
    
    // Initialize new page
    await this.initializePage();
  }

  // Stop tracking
  stopTracking = async () => {
    if (!this.isTracking) return;

    // Remove event listeners
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('focus', this.handleWindowFocus);
    window.removeEventListener('blur', this.handleWindowBlur);

    // Clear the update interval
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    // Send final update for current page
    await this.updateTimeSpent();
    
    this.isTracking = false;
    this.startTime = null;
    this.pausedTime = 0;
    this.lastPauseTime = null;
  }
}

const tracker = new UserActivityTracker();
export default tracker;